var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sidebar"}},[_c('div',{attrs:{"id":"sidebar-scroll"}},[_c('div',{staticClass:"sidebar-content"},[_c('a',{staticClass:"sidebar-brand"},[_c('span',{staticClass:"sidebar-nav-mini-hide"},[_c('img',{attrs:{"src":require('@/assets/logo.png'),"alt":""}})])]),_c('ul',{staticClass:"sidebar-nav"},[_vm._l((_vm.navItems),function(item){return _c('li',{key:item.name,on:{"click":function($event){return _vm.toggleNavItem(item)}}},[_c('router-link',{staticClass:"sidebar-nav-menu",class:{
              open: item.toggle,
              active: _vm.checkRoute(item.url)
            },attrs:{"to":item.url}},[(item.children)?_c('i',{staticClass:"fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"}):_vm._e(),_c('i',{staticClass:"sidebar-nav-icon",class:item.icon}),_c('span',{staticClass:"sidebar-nav-mini-hide"},[_vm._v(_vm._s(item.name))])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(item.toggle),expression:"item.toggle"}]},_vm._l((item.children),function(child){return _c('li',{key:child.name},[_c('router-link',{class:{
                  active: _vm.checkRoute(child.url, item)
                },attrs:{"to":child.url}},[_vm._v(_vm._s(child.name))])],1)}),0)],1)}),(_vm.Info.agency == 1)?_c('li',{},[_c('router-link',{staticClass:"sidebar-nav-menu",class:{
              active: _vm.checkRoute('static-agency')
            },attrs:{"to":"/static-agency"}},[_c('i',{staticClass:"sidebar-nav-icon fa fa-line-chart"}),_c('span',{staticClass:"sidebar-nav-mini-hide"},[_vm._v("Static Agency")])])],1):_vm._e(),_c('li',{},[_vm._m(0),_c('div',{staticClass:"dropdown-menu content_document",staticStyle:{"position":"relative","width":"100%","background":"#fff0","border":"none","box-shadow":"none","padding-left":"30px"},attrs:{"aria-labelledby":"dropdownMenuButton"}},_vm._l((_vm.list_document.list),function(item){return _c('a',{key:item.Doc_ID * 12,staticClass:"dropdown-item",attrs:{"target":"_blank","href":item.Doc_File}},[_c('i',{staticClass:"sidebar-nav-icon fa fa-circle-o",staticStyle:{"margin-right":"0","font-size":"12px"},attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.Doc_Title))])}),0)])],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"sidebar-nav-menu dropdown-toggle",attrs:{"href":"javascript:void()","type":"button","id":"dropdownMenuButton","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"sidebar-nav-icon fa fa-file-text",attrs:{"aria-hidden":"true"}}),_vm._v(" Document ")])}]

export { render, staticRenderFns }