<template>
  <header class="navbar navbar-default" style="">
    <ul class="nav navbar-nav-custom">
      <li>
        <a href="javascript:void(0)" @click="$emit('toggle-sidebar')">
          <i class="fa fa-bars fa-fw"></i>
        </a>
      </li>
    </ul>

    <ul class="nav navbar-nav-custom pull-right">
      <li
        @click="visible = !visible"
        class="custom-log mr-5"
        style="background:#fff0;padding-top:4px"
        ref="dropdownMenu"
      >
        <div class="notification">
          <svg viewBox="0 0 14 18" style="height:30px">
            <path
              d="M6.98676451,16.6014445 C8.2531732,16.6014445 9.28762307,15.575713 9.28762307,14.3160178 L4.67870227,14.3160178 C4.67870227,15.575713 5.72035583,16.6014445 6.98676451,16.6014445 L6.98676451,16.6014445 Z"
              class="notification--bellClapper"
            ></path>
            <path
              d="M12.3259472,11.3903764 L12.3259472,6.91722025 C12.3259472,4.39382319 10.6003146,2.35964578 8.21729813,1.79211029 L8.21729813,1.21948935 C8.21729813,0.568552583 7.64311442,7.45855854e-15 6.98573057,7.45855854e-15 C6.32731956,7.45855854e-15 5.75416302,0.568552583 5.75416302,1.21948935 L5.75416302,1.79211029 C3.3701194,2.35964578 1.64551395,4.39382319 1.64551395,6.91722025 L1.64551395,11.3903764 L0,13.0197525 L0,13.8344405 L13.9714611,13.8344405 L13.9714611,13.0197525 L12.3259472,11.3903764 Z"
              class="notification--bell"
            ></path>
          </svg>
          <span class="notification--num">{{ lists.count_new }}</span>
        </div>
        <div class="content-noti" :class="{ active: visible }">
          <ul>
            <li v-for="item in lists.list" :key="item.id * 123">
              <div class="d-flex" :class="{ active: item.is_new == 1 }">
                <div class="icon">
                  <i
                    class="fa fa-exclamation-triangle"
                    aria-hidden="true"
                    style="color: rgb(128 128 128)"
                  ></i>
                </div>
                <div class="content" style="background: #fff;padding:10px">
                  <h3>{{ item.en_title }}</h3>
                  <p>{{ item.en_content }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li @click="handleProfile" class="custom-log mr-5">
        <i class="fa fa-user" aria-hidden="true"></i>
      </li>
      <li @click="handleSignOut" class="custom-log">
        <i class="fa fa-power-off" aria-hidden="true"></i>
      </li>
    </ul>
  </header>
</template>

<script>
import { removeAuthToken } from "@/services/auth";
import { mapGetters } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      visible: false
    };
  },
  computed: {
    ...mapGetters({
      lists: "dashboard/Notification",
      list_document: "dashboard/Document"
    })
  },
  created() {
    this.$store.dispatch("dashboard/req_getNotification");
    this.$store.dispatch("dashboard/req_getDocument");
  },
  methods: {
    handleSignOut() {
      removeAuthToken();
      this.$router.push({ name: "signin" });
    },
    documentClick(e) {
      let el = this.$refs.dropdownMenu;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.visible = false;
      }
    },
    handleProfile() {
      this.$router.push({ name: "profile" });
    }
  }
};
</script>
<style scoped>
.d-flex {
  display: flex;
}
.d-flex .icon {
  width: 40px;
  height: 100%;
  padding: 10px;
  display: flex;
  align-self: center;
  justify-content: center;
}
.d-flex .content {
  width: calc(100% - 40px);
  padding-left: 10px;
}
.custom-log {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px !important;
  min-height: 40px !important;
  cursor: pointer;
  background: black;
  border-radius: 50%;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  margin-top: 5px;
  margin-right: 15px;
}
.document {
  background: #fff0;
}
.document a {
  background: #fff0 !important;
  border-radius: 50%;
}
.content_document {
  width: 200px;
  padding: 15px;
  right: 0;
  left: auto;
}
.content_document a {
  color: #444;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  text-decoration: none;
  line-height: 1.35;
  transition: 0.3s;
}
.content_document a:hover {
  color: #73bc47;
}
.content_document a:last-child {
  margin-bottom: 0;
}
.content-noti {
  position: absolute;
  padding: 0 0;
  background: #fff;
  border-radius: 5px;
  top: 150px;
  width: 350px;
  max-height: 300px;
  overflow-y: auto;
  right: -30px;
  transition: 0.3s;
  opacity: 0;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.3);
  z-index: -111;
}
@media only screen and (max-width: 767px) {
  .content-noti {
    width: 300px;
    right: -100px;
  }
}
.content-noti ul {
  margin: 0;
  padding: 0;
}
.content-noti ul li .d-flex {
  list-style: none;
  margin: 0 0 0px;
  padding: 0px 0px;
  background: #fff;
  word-break: break-all;
  border-left: 4px solid #fffdd9;
  border-bottom: 2px #eee solid;
}
.content-noti ul li .active {
  background: #fffdd9 !important;
  border-left-color: red;
}
.content-noti ul li .active .content {
  background: #fffdd9 !important;
}
.content-noti ul li .active i {
  color: red !important;
}
.content-noti ul li:nth-child(2n + 1) {
  /* border-left-color: #ec1c25; */
}
.content-noti ul li h3 {
  margin: 0 0 5px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}
.content-noti ul li p {
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.35;
  word-break: break-word;
}
.content-noti ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0px #eee solid;
}
.content-noti.active {
  z-index: 22;
  opacity: 1;
  top: 50px;
}
.custom-log i {
  font-size: larger;
  color: white;
  font-weight: bold;
  filter: drop-shadow(5px 5px 5px black);
  margin: 0;
}
.custom-log:hover {
  background: #acacac;
}
.mr-5 {
  margin-right: 5px !important;
}
.custom-log.mr-5 {
  background: #fff;
}
.custom-log.mr-5 i {
  color: #4eb04a;
  filter: none;
}
.notification {
  position: relative;
  width: 30px;
  height: 30px;
}
.notification svg {
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
}
.notification svg > path {
  fill: #ffffff;
}
.notification--bell {
  animation: bell 2.2s linear infinite;
  transform-origin: 50% 0%;
}
.notification--bellClapper {
  animation: bellClapper 2.2s 0.1s linear infinite;
}
.notification--num {
  position: absolute;
  top: 1.5%;
  left: 51%;
  font-size: 10px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #ff4c13;
  color: #ffffff;
  text-align: center;
  line-height: 13px;
  animation: notification 3.2s ease;
}

.notification--num--pill {
  position: absolute;
  top: 1.5%;
  left: 51%;
  font-size: 10px;
  border-radius: 2em;
  width: 30px;
  height: 14px;
  background-color: #ff4c13;
  color: #ffffff;
  text-align: center;
  line-height: 13px;
  animation: notification 3.2s ease;
}

@keyframes bell {
  0%,
  25%,
  75%,
  100% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(4deg);
  }
  45% {
    transform: rotate(-4deg);
  }
  55% {
    transform: rotate(2deg);
  }
  60% {
    transform: rotate(-2deg);
  }
}
@keyframes bellClapper {
  0%,
  25%,
  75%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-0.15em);
  }
  45% {
    transform: translateX(0.15em);
  }
  55% {
    transform: translateX(-0.1em);
  }
  60% {
    transform: translateX(0.1em);
  }
}
@keyframes notification {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  30%,
  70% {
    opacity: 0;
  }
}
</style>
