<template>
  <div id="sidebar">
    <div id="sidebar-scroll">
      <div class="sidebar-content">
        <a class="sidebar-brand">
          <span class="sidebar-nav-mini-hide">
            <img :src="require('@/assets/logo.png')" alt="" />
          </span>
        </a>

        <ul class="sidebar-nav">
          <li v-for="item in navItems" :key="item.name" @click="toggleNavItem(item)">
            <router-link
              :to="item.url"
              class="sidebar-nav-menu"
              :class="{
                open: item.toggle,
                active: checkRoute(item.url)
              }"
            >
              <i
                v-if="item.children"
                class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"
              ></i>
              <i class="sidebar-nav-icon" :class="item.icon"></i>
              <span class="sidebar-nav-mini-hide">{{ item.name }}</span>
            </router-link>
            <ul v-show="item.toggle">
              <li v-for="child in item.children" :key="child.name">
                <router-link
                  :to="child.url"
                  :class="{
                    active: checkRoute(child.url, item)
                  }"
                  >{{ child.name }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="" v-if="Info.agency == 1">
            <router-link
              to="/static-agency"
              class="sidebar-nav-menu"
              :class="{
                active: checkRoute('static-agency')
              }"
            >
              <i class="sidebar-nav-icon fa fa-line-chart"></i>
              <span class="sidebar-nav-mini-hide">Static Agency</span>
            </router-link>
          </li>
          <!-- <li class="">
            <router-link
              :to="topLeader == 0 ? '' : { path: '/top-trading' }"
              class="sidebar-nav-menu"
              @click.native="topLeader == 0 ? clickCommingSoon() : ''"
              :class="{
                active: checkRoute('top-trading')
              }"
            >
              <i class="sidebar-nav-icon fa fa-line-chart"></i>
              <span class="sidebar-nav-mini-hide">Top Trading</span>
            </router-link>
          </li> -->
          <li class="">
            <a
              href="javascript:void()"
              class="sidebar-nav-menu dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="sidebar-nav-icon fa fa-file-text" aria-hidden="true"></i> Document
            </a>
            <div
              class="dropdown-menu content_document"
              aria-labelledby="dropdownMenuButton"
              style="position:relative;width:100%;background:#fff0;border:none;box-shadow:none;padding-left:30px"
            >
              <a
                class="dropdown-item"
                target="_blank"
                :href="item.Doc_File"
                v-for="item in list_document.list"
                :key="item.Doc_ID * 12"
              >
                <i
                  class="sidebar-nav-icon fa fa-circle-o"
                  aria-hidden="true"
                  style="margin-right:0;font-size:12px"
                ></i>
                {{ item.Doc_Title }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  data: () => ({
    navItems: [
      {
        icon: "gi gi-home",
        name: "Dashboard",
        url: "/"
      },
      {
        icon: "fa fa-users",
        name: "Member",
        url: "/member"
      },
      {
        icon: "fa fa-trophy",
        name: "Game",
        url: "/game"
      },
      {
        icon: "gi gi-money",
        name: "Wallet",
        url: "/wallet"
      },
      //     {
      //   icon: "fa fa-id-card-o",
      //   name: "Profile",
      //   url: "/profile"
      // },
      {
        icon: "fa fa-bar-chart",
        name: "Reports",
        url: "",
        toggle: false,
        children: [
          { name: "Win/Loss", url: "/win-loss-report" },
          { name: "Transaction", url: "/transaction-detail" },
          { name: "Sign In", url: "/signin-detail" }
        ]
      },
      {
        icon: "fa fa-question-circle",
        name: "Support",
        url: "/support"
      },
      {
        icon: "fa fa-circle-o-notch",
        name: "Lucky Spin",
        url: "/lucky-while"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      Info: "dashboard/Info",
      list_document: "dashboard/Document",
      topLeader: "dashboard/TopLeader"
    })
  },
  created() {
    this.$store.dispatch("dashboard/req_getDocument");
  },
  methods: {
    clickCommingSoon() {
      alert("Coming soon!");
    },
    toggleNavItem(item) {
      item.toggle = !item.toggle;
    },
    checkRoute(url, parent) {
      if (this.$route.path !== url) return false;

      if (parent) parent.toggle = true;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-nav ul {
  display: block;
}
span.sidebar-nav-mini-hide img {
  height: 100%;
  text-align: center;
  margin: auto;
}
</style>
