<template>
  <div id="page-wrapper">
    <div
      id="page-container"
      class="sidebar-partial sidebar-no-animations"
      :class="{ 'sidebar-visible-lg': sidebarToggle, 'sidebar-visible-xs': !sidebarToggle }"
    >
      <custom-sidebar></custom-sidebar>
      <div id="main-container">
        <custom-navbar @toggle-sidebar="sidebarToggle = !sidebarToggle"></custom-navbar>
        <div id="page-content">
          <!--<div class="content-header">
            <ul class="nav-horizontal text-center">
              <li
                v-for="{ name, icon, url } in navItems"
                :key="url"
                :class="{ active: checkRoute(url) }"
              >
                <router-link :to="url"><i :class="icon"></i>{{ name }}</router-link>
              </li>
            </ul>
          </div>-->
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSidebar from "@/components/shared/Sidebar";
import CustomNavbar from "@/components/shared/Navbar";

export default {
  name: "BaseLayout",
  components: {
    CustomSidebar,
    CustomNavbar
  },
  data: () => ({
    sidebarToggle: true,
    navItems: [
      {
        icon: "fa fa-bar-chart",
        name: "Dashboard",
        url: "/"
      },
      {
        icon: "fa fa-users",
        name: "Member",
        url: "/member"
      },
      {
        icon: "fa fa-trophy",
        name: "Game",
        url: "/game"
      },
      {
        icon: "gi gi-money",
        name: "Wallet",
        url: "/wallet"
      },
      {
        icon: "fa fa-id-card",
        name: "Sub Account",
        url: "/sub-account"
      },
      {
        icon: "fa fa-question-circle",
        name: "Support",
        url: "/support"
      }
    ]
  }),
  methods: {
    checkRoute(url) {
      return this.$route.path === url;
    }
  }
};
</script>

<style lang="scss" scoped>
#main-container,
#page-content {
  min-height: 100vh;
}
</style>
