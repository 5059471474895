<template>
  <base-layout>
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="block-title"><h2 data-v-25fdef5e="">Static Agency</h2></div>
          <div class="table-responsive" style="padding:15px 5px">
            <div class="row">
              <div class="form-group col-md-3">
                <input v-model="userID" type="text" class="form-control" placeholder="User id" />
              </div>
              <div class="form-group col-md-3">
                <input
                  v-model="userEmail"
                  type="text"
                  class="form-control"
                  placeholder="User email"
                />
              </div>
              <div class="form-group col-md-3">
                <input v-model="user_f" type="text" class="form-control" placeholder="User f" />
              </div>
              <div class="form-group col-md-3">
                <button type="button" class="btn btn-primary" @click="handleSearch">
                  <i class="fa fa-search"></i> Search
                </button>
              </div>
            </div>
            <table
              class="table table-vcenter table-bordered table-hover table-striped table-condensed"
            >
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Email</th>
                  <th>Level Agency</th>
                  <th>Parent</th>
                  <th>F</th>
                  <th>Volume Trade</th>
                  <th>F1's volume trade</th>
                  <th>F2's volume trade</th>
                  <th>F3's volume trade</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listStaticAgency.list" :key="item.User_ID">
                  <td>{{ item.User_ID }}</td>
                  <td>{{ item.UserEmail }}</td>
                  <td>{{ item.LevelAgency }}</td>
                  <td>{{ item.Parent }}</td>
                  <td>{{ item.F }}</td>
                  <td>{{ item.VolumeTrade }}</td>
                  <td>{{ item.BranchTrade.branch_trade[1] }}</td>
                  <td>{{ item.BranchTrade.branch_trade[2] }}</td>
                  <td>{{ item.BranchTrade.branch_trade[3] }}</td>
                  <td>{{ item.Balance }}</td>
                </tr>
              </tbody>
              <tr v-if="listStaticAgency.total_page > 1">
                <td colspan="9" class="text-right">
                  <paginate
                    v-model="page"
                    :page-count="listStaticAgency.total_page"
                    :click-handler="handlePagination"
                    :prev-text="'Prev'"
                    :next-text="'Next'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                  ></paginate>
                </td>
              </tr>
            </table>

            <div
              v-if="listStaticAgency.list && listStaticAgency.list.length <= 0"
              class="block-section"
            >
              No static agency
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import { mapGetters } from "vuex";
import BaseLayout from "./layouts/Base";

export default {
  name: "staticAgency",
  components: {
    BaseLayout
  },
  data: () => ({
    page: 1,
    userID: "",
    userEmail: "",
    user_f: ""
  }),

  computed: {
    ...mapGetters({
      listStaticAgency: "member/ListStaticAgency"
    })
  },
  methods: {
    async fetchReport() {
      this.$store.dispatch("member/req_getStaticAgency", {
        page: this.page,
        user_id: this.userID,
        user_email: this.userEmail,
        user_f: this.user_f
      });
    },
    handleSearch() {
      this.fetchReport(this.page);
    },
    handlePagination() {
      this.fetchReport();
    }
  },
  created() {
    this.fetchReport();
    this.$store.dispatch("member/req_getStaticAgency");
  }
};
</script>
<style scoped>
.table thead > tr > th {
  font-size: 14px;
  font-weight: 600;
}
</style>
